/* eslint @typescript-eslint/no-var-requires: "off" */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useTitle = () => {
  const { ProjectName } = require(`../Apps/${process.env.REACT_APP_ID}/config`);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    let pathFormatted = currentPath;
    if (currentPath.includes('/') && currentPath.length > 1) {
      pathFormatted = pathFormatted.split('/')[1];
      pathFormatted = `${pathFormatted[0].toUpperCase()}${pathFormatted.slice(1)}`;
      pathFormatted = pathFormatted.replace('-', ' ');
    } else {
      pathFormatted = '';
    }

    const pageTitle = `${!!pathFormatted ? pathFormatted + ' - ' : ''}${ProjectName}`;
    document.title = pageTitle;
  }, [currentPath]);
};

export default useTitle;
