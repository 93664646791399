/* eslint-disable @typescript-eslint/no-namespace,@typescript-eslint/ban-types */
// @ts-ignore
import { API, API as AccountAPI } from 'account-types';

export namespace MinderseTypes {
  import Endpoint = AccountAPI.Endpoint;
  import HttpMethod = AccountAPI.HttpMethod;

  export const PREFIX = '/api/v1';

  export type BookParams = {
    name: string;
    email: string;
    fontColor: string;
    backgroundColor: string;
  };

  export namespace Book {
    export namespace Create {
      export const endpoint: Endpoint = { URL: '/book/create', method: HttpMethod.POST };

      export type Req = BookParams;

      export type Resp = {};
    }
  }
}
