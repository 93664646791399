import React, { Suspense } from 'react';
import dotenv from 'dotenv';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import usePageTracking from './helpers/usePageTracking';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ProductProvider } from './context/ProductProvider';
import TagManager from 'react-gtm-module';
import { GTM_ID, PAYPAL_CLIENT_ID, STRIPE_KEY, STRIPE_SECRET, APP_ID } from './config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GeneralProvider } from './context/GeneralProvider';
import Modal from 'react-modal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthProvider } from './context/AuthProvider';
import useFunnel from './hooks/useFunnel';
import useTitle from './hooks/useTitle';

const AppContent = React.lazy(() => import(`./Apps/${process.env.REACT_APP_ID}`));

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const initialOptions = {
  'client-id': PAYPAL_CLIENT_ID,
  currency: 'USD',
  intent: 'capture',
  vault: true,
  'disable-funding': 'credit,card',
};

const stripePromise = loadStripe(STRIPE_KEY);

const stripeOptions = {
  clientSecret: STRIPE_SECRET,
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

function App(): JSX.Element {
  usePageTracking();
  useFunnel();
  useTitle();

  return (
    <Suspense fallback={''}>
      <AppContent />
    </Suspense>
  );
}

Modal.setAppElement('#root');

let pageContent = (
  <Elements stripe={stripePromise} options={stripeOptions}>
    <QueryClientProvider client={queryClient}>
      <GeneralProvider>
        <ProductProvider>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </ProductProvider>
      </GeneralProvider>
    </QueryClientProvider>
  </Elements>
);

if (!!PAYPAL_CLIENT_ID) {
  pageContent = (
    <PayPalScriptProvider options={initialOptions}>{pageContent}</PayPalScriptProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>{pageContent}</React.StrictMode>,
  document.getElementById('root'),
);
