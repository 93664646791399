import ProductContext from './ProductContext';
import React, { Component } from 'react';
import { ProductContextStateType, ProductContextType } from '../types';
import { findProductBySku } from '../helpers/products';
import { PaymentMethods } from '../const';

class ProductProvider extends Component<React.FC> {
  initialState = {
    selectedProduct: findProductBySku(),
    isPurchased: false,
    isBookPurchased: false,
    paymentMethod: PaymentMethods.paypal,
  };
  state = { ...this.initialState };

  updateState = (partialState: Partial<ProductContextStateType>): void => {
    this.setState((prevState) => {
      return { ...prevState, ...partialState };
    });
  };

  render(): JSX.Element {
    return (
      <ProductContext.Provider
        value={{
          state: this.state,
          updateState: this.updateState,
        }}
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}

const useProductContext = (): ProductContextType => React.useContext(ProductContext);

export { useProductContext, ProductProvider };
