import { ConfigTypes } from './types';

const getConfig = (configName: ConfigTypes): string => process.env[configName] ?? '';

export const APP_ID = getConfig(ConfigTypes.REACT_APP_ID);
export const GTM_ID = getConfig(ConfigTypes.REACT_APP_GTM_ID);
export const PAYPAL_CLIENT_ID = getConfig(ConfigTypes.REACT_APP_PAYPAL_CLIENT_ID);
export const API_URL = getConfig(ConfigTypes.REACT_APP_API_URL);
export const STRIPE_KEY = getConfig(ConfigTypes.REACT_APP_STRIPE_KEY);
export const STRIPE_SECRET = getConfig(ConfigTypes.REACT_APP_STRIPE_SECRET);
