import React from 'react';
import { GeneralContextStateType, GeneralContextType } from '../types';
import { PaymentTypes } from '../const';
import useFunnel from '../hooks/useFunnel';
import GeneralContext from './GeneralContext';

interface IProps {
  children: React.ReactNode;
}

const GeneralProvider: React.FC<IProps> = ({ children }: IProps) => {
  const { funnel } = useFunnel();
  const [state, setState] = React.useState<GeneralContextStateType>({
    country: '',
    funnel,
    paymentType: PaymentTypes.default,
    isLoading: true,
  });

  const updateState = (partialState: Partial<GeneralContextStateType>) => {
    setState((prevstate) => {
      return { ...prevstate, ...partialState };
    });
  };

  return (
    <GeneralContext.Provider value={{ state, updateState }}>
      {children}
    </GeneralContext.Provider>
  );
};

const useGeneralContext = (): GeneralContextType => React.useContext(GeneralContext);

export { useGeneralContext, GeneralProvider };
