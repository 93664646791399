import { IProduct } from '../types';

export const findProductBySku = (
  products?: IProduct[] | undefined,
  sku?: string,
): IProduct => {
  const allProducts: IProduct[] = products ?? [];

  const defaultValue: IProduct = {
    id: '',
    name: '',
    price: 0,
    discountAmount: 0,
    normalAmount: 0,
    currency: 'USD',
    subscriptionMeasure: 'day',
    subscriptionPeriod: 0,
    type: 'subscription',
  };

  let foundProduct = defaultValue;

  if (!!products) {
    foundProduct = allProducts.find((product) => product.sku == sku) ?? defaultValue;
    foundProduct.discountAmount = 0;
    foundProduct.normalAmount = 0;

    if (foundProduct.discountPercentage) {
      foundProduct.discountAmount = parseFloat(
        (
          (foundProduct.discountPercentage * foundProduct.price) /
          (100 - foundProduct.discountPercentage)
        ).toFixed(2),
      );

      foundProduct.normalAmount = Math.ceil(
        parseFloat(
          (foundProduct.price / (-foundProduct.discountPercentage / 100.0 + 1.0)).toFixed(
            2,
          ),
        ),
      );
    }
  }

  return foundProduct;
};
