import { createContext } from 'react';
import { GeneralContextType } from '../types';

const GeneralContext = createContext<GeneralContextType>({
  state: {},
  updateState: () => {
    console.log('default update fn');
  },
});

export default GeneralContext;
