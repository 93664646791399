/* eslint @typescript-eslint/no-var-requires: "off" */
import React, { useEffect } from 'react';
import { useGeneralContext } from '../context/GeneralProvider';
import { PaymentTypes } from '../const';
import { getParam } from '../helpers/qs.utils';
import { useQuery } from 'react-query';
import { API as AccountTypes, API } from 'account-types';
import { get } from '../helpers/api.utils';
import { IProductResponse } from '../types';

const getLead = async (email: string) => {
  return get<AccountTypes.Leads.Get.Resp>(AccountTypes.Leads.Get.endpoint.URL, { email });
};

const useFunnel = () => {
  const { Funnels } = require(`../Apps/${process.env.REACT_APP_ID}/config`);

  const [funnelType, setFunnelType] = React.useState<any>(
    getParam('type', location.search),
  );

  const [paymentsMethod, setPaymentsMethod] = React.useState<any>(
    getParam('method', location.search),
  );

  const emailFromGet = getParam('email', location.search);

  const {
    updateState,
    state: { funnel },
  } = useGeneralContext();

  const getProducts = () => get<IProductResponse>(API.Product.List.endpoint.URL);

  const productsQuery = useQuery(API.Product.List.endpoint.URL, () => getProducts(), {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onError: (error) => {
      console.log('error', error);
    },
  });

  React.useEffect(() => {
    if (!funnel) {
      // funnel always undefined on initial load
      const defaultFunnel = Funnels.default.Name;
      let funnelName = defaultFunnel;
      let paymentsTypeFromGet = '';
      const stateData = {};

      const data = localStorage.getItem('profile');
      const userData = data ? JSON.parse(data) : {};

      if (!!userData && userData.funnel) {
        funnelName = userData.funnel;
        if (!!userData.paymentType) stateData['paymentType'] = userData.paymentType;
        if (!!userData.email) stateData['email'] = userData.email;
      }

      if (!!funnelType) {
        funnelName = String(funnelType.toString().toLowerCase() as keyof typeof Funnels);
        userData.funnel = funnelName;
      }

      if (!!funnelName) {
        funnelName = Funnels[funnelName] ? funnelName : defaultFunnel;
        const funnelConfig = Funnels[funnelName];
        funnelConfig.Name = funnelName;

        if (funnelConfig) {
          stateData['funnel'] = funnelConfig;
          userData.funnel = funnelName;
        }
      }
      if (!!paymentsMethod) {
        paymentsTypeFromGet = PaymentTypes[paymentsMethod];
        if (!!paymentsTypeFromGet) {
          stateData['paymentType'] = paymentsTypeFromGet;
          userData.paymentType = paymentsTypeFromGet;
        }
      }

      if (!!emailFromGet) {
        stateData['email'] = emailFromGet;
        userData.email = emailFromGet;

        if (typeof emailFromGet === 'string') {
          getLead(emailFromGet).then((lead) => {
            if (lead) {
              stateData['quizData'] = lead['quizData'];
              userData.quizData = lead['quizData'];
              localStorage.setItem('profile', JSON.stringify(userData));
              window.dispatchEvent(new Event('profileUpdated'));
              updateState(stateData);
            }
          });
        }
      } else {
        localStorage.setItem('profile', JSON.stringify(userData));
        window.dispatchEvent(new Event('profileUpdated'));
      }

      console.log('productsQuery.refetch()');

      // getting products
      productsQuery.refetch().then((productResponse) => {
        if (!productResponse.data) {
          return;
        }

        const { list: rawList } = productResponse.data;

        stateData['products'] = rawList || [];
        stateData['isLoading'] = false;
        if (stateData) {
          updateState(stateData);
        }
      });
    }
  }, []);

  return {
    funnel,
    funnelType,
    setFunnelType,
  };
};

export default useFunnel;
