import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { API_URL } from '../config';
import { APIClient } from './APIClient';

interface IPromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

async function wrapRequestPromise<Response>(
  requestPromise: IPromiseWithCancel<AxiosResponse>,
): Promise<Response> {
  try {
    const response = await requestPromise;
    return response.data as Response;
  } catch (error) {
    throw error;
  }
}

const buildEndpoint = (endpoint = '', excludeLocale = false): string => {
  return endpoint;
};

const getRequestConfig = (
  params?: unknown,
  headers?: Record<string, string>,
): AxiosRequestConfig => {
  return {
    baseURL: API_URL,
    params,
    paramsSerializer: (params: Record<string, unknown>) => {
      return qs.stringify(params);
    },
    headers,
  };
};

export function get<Response>(
  endpoint: string,
  params?: unknown,
  excludeLocale = false,
): Promise<Response> {
  const requestConfig = getRequestConfig(params);
  const builtEndpoint = buildEndpoint(endpoint, excludeLocale);

  const requestPromise = axios.get(
    builtEndpoint,
    requestConfig,
  ) as IPromiseWithCancel<AxiosResponse>;

  return wrapRequestPromise(requestPromise);
}

export function post<Response>(
  endpoint: string,
  data?: Record<string, unknown>,
  params?: unknown,
  headers?: Record<string, string>,
  excludeLocale = false,
): Promise<Response> {
  const requestConfig = getRequestConfig(params, headers);
  const builtEndpoint = buildEndpoint(endpoint, excludeLocale);

  const requestBuilder = axios.post(
    builtEndpoint,
    data,
    requestConfig,
  ) as IPromiseWithCancel<AxiosResponse>;

  return wrapRequestPromise(requestBuilder);
}

export function generateAuthHeaderObject(): { Authorization: string } {
  const authToken = localStorage.getItem('authToken');
  return { Authorization: `Bearer ${authToken}` };
}

export const apiClient = () => {
  const client = new APIClient(API_URL);

  const authToken = localStorage.getItem('authToken');
  client.setAuthToken(authToken);

  return client;
};
