import { PaymentMethods } from './const';
import { API } from 'account-types';

export type ProductContextStateType = {
  selectedProduct: IProduct;
  cart?: IProduct[];
  isPurchased: boolean;
  upsellsPurchased?: IProduct[];
  paymentMethod: PaymentMethods;
  paymentMethodId?: string;
  funnelType?: string;
};

export type ProductContextType = {
  state: ProductContextStateType;
  updateState: (partialState: Partial<ProductContextStateType>) => void;
};

export type IAuth = { token: string };

export type AuthContextType = {
  state: {
    token: string;
  };
  updateState: (val: IAuth) => void;
};

export interface Funnel {
  Name?: string;
  Landing: {
    heading: string;
    description: string;
  };
  Quiz: {
    quizFlow: any[];
    defaultProperties?: any[];
  };
  GetStarted: {
    description: string;
    button: string;
  };
  Summary: {
    heading: string;
    solution: string;
    resultsTitle: string;
    suggestedPlan: string;
    issue: string;
    summary: any[];
  };
  Purchase: {
    suggestedPlan: string;
    promise: string;
    subtitle?: string;
    benefits: any[];
    chart: any;
    newspaper: string;
    scienceBacked: any[];
  };
}

export type GeneralContextStateType = {
  email?: string;
  quizData?: any;
  isLoading?: boolean;
  country?: string;
  funnel?: Funnel;
  paymentType?: any;
  leadId?: string;
  products?: IProduct[];
};

export type GeneralContextType = {
  state: GeneralContextStateType;
  updateState: (partialState: Partial<GeneralContextStateType>) => void;
};

export type IProduct = API.Product & {
  discountAmount: number;
  normalAmount: number;
};

export enum ConfigTypes {
  REACT_APP_ID = 'REACT_APP_ID',
  REACT_APP_PAYPAL_CLIENT_ID = 'REACT_APP_PAYPAL_CLIENT_ID',
  REACT_APP_GTM_ID = 'REACT_APP_GTM_ID',
  REACT_APP_API_URL = 'REACT_APP_API_URL',
  REACT_APP_STRIPE_KEY = 'REACT_APP_STRIPE_KEY',
  REACT_APP_STRIPE_SECRET = 'REACT_APP_STRIPE_SECRET',
}

interface BaseApiResponse {
  status: boolean;
  message: string;
}

interface BaseApiPurchaseResponse extends BaseApiResponse {
  data: {
    new_client_secret?: string;
  };
  error?: unknown;
}

export type IPurchaseUpsellResponse = BaseApiPurchaseResponse;

export type IPurchasePlanResponse = BaseApiPurchaseResponse;

export interface ISetupIntentResponse extends BaseApiResponse {
  client_secret: string;

  error?: unknown;
}

export interface IProductResponse extends BaseApiResponse {
  list: IProduct[];
}

export interface IUpdateUserResponse extends BaseApiResponse {
  data: string;
}
