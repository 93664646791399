import { API as AccountAPI } from 'account-types';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import qs from 'qs';
import { MinderseTypes } from './minderse-types';

type ApiResponse<T> = Promise<AxiosResponse<T>>;

export class APIClient {
  private axios: AxiosInstance;
  private headers: Record<string, string> = {};
  private readonly baseUrl: string;

  constructor(baseUrl: string = API_URL + '/api/v1') {
    this.baseUrl = baseUrl;

    this.axios = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: function (_) {
        return true; // we don't raise exception for any status
      },
      paramsSerializer: (params: any) => qs.stringify(params),
    });
  }

  middleware?: <T>(response: AxiosResponse<T>) => void;

  public setAuthToken = (token?: string | null) => {
    this.headers.Authorization = 'Bearer ' + token;
    return this;
  };

  private _ = async <T, D>(
    request: D,
    endpoint: AccountAPI.Endpoint,
  ): // @ts-ignore
  ApiResponse<T> => {
    const url = this.baseUrl + endpoint.URL;

    let resp: AxiosResponse<T>;

    switch (endpoint.method) {
      case AccountAPI.HttpMethod.GET:
        resp = await this.axios.get(url + '?' + qs.stringify(request), {
          headers: this.headers,
        });
        break;
      case AccountAPI.HttpMethod.DELETE:
        resp = await this.axios.delete(url + '?' + qs.stringify(request), {
          headers: this.headers,
        });
        break;
      case AccountAPI.HttpMethod.HEAD:
        resp = await this.axios.head(url + '?' + qs.stringify(request), {
          headers: this.headers,
        });
        break;
      case AccountAPI.HttpMethod.OPTIONS:
        resp = await this.axios.options(url + '?' + qs.stringify(request), {
          headers: this.headers,
        });
        break;
      case AccountAPI.HttpMethod.POST:
        resp = await this.axios.post(url, request, { headers: this.headers });
        break;
      case AccountAPI.HttpMethod.PUT:
        resp = await this.axios.put(url, request, { headers: this.headers });
        break;
      case AccountAPI.HttpMethod.PATCH:
        resp = await this.axios.patch(url, request, { headers: this.headers });
        break;
    }

    if (this.middleware) {
      this.middleware(resp);
    }

    return resp;
  };

  auth = {
    signup: (
      request: AccountAPI.Auth.Signup.Req,
    ): ApiResponse<AccountAPI.Auth.Signup.Resp> =>
      this._(request, AccountAPI.Auth.Signup.endpoint),
    login: (
      request: AccountAPI.Auth.Login.Req,
    ): ApiResponse<AccountAPI.Auth.Login.Resp> =>
      this._(request, AccountAPI.Auth.Login.endpoint),
    backendLogin: (
      request: AccountAPI.Auth.BackendLogin.Req,
    ): ApiResponse<AccountAPI.Auth.BackendLogin.Resp> =>
      this._(request, AccountAPI.Auth.BackendLogin.endpoint),
    resetPassword: (
      request: AccountAPI.Auth.ResetPassword.Req,
    ): ApiResponse<AccountAPI.Auth.ResetPassword.Resp> =>
      this._(request, AccountAPI.Auth.ResetPassword.endpoint),
    forgotPassword: (
      request: AccountAPI.Auth.ForgotPassword.Req,
    ): ApiResponse<AccountAPI.Auth.ForgotPassword.Resp> =>
      this._(request, AccountAPI.Auth.ForgotPassword.endpoint),
  };

  book = {
    create: (
      request: MinderseTypes.Book.Create.Req,
    ): ApiResponse<MinderseTypes.Book.Create.Resp> =>
      this._(request, MinderseTypes.Book.Create.endpoint),
  };

  user = {
    profile: (
      request: AccountAPI.User.Profile.Req,
    ): ApiResponse<AccountAPI.User.Profile.Resp> =>
      this._(request, AccountAPI.User.Profile.endpoint),
    update: (
      request: AccountAPI.User.Update.Req,
    ): ApiResponse<AccountAPI.User.Update.Resp> =>
      this._(request, AccountAPI.User.Update.endpoint),
    updatePassword: (
      request: AccountAPI.User.UpdatePassword.Req,
    ): ApiResponse<AccountAPI.User.UpdatePassword.Resp> =>
      this._(request, AccountAPI.User.UpdatePassword.endpoint),
    deleteAccount: (
      request: AccountAPI.User.DeleteAccount.Req,
    ): ApiResponse<AccountAPI.User.DeleteAccount.Resp> =>
      this._(request, AccountAPI.User.DeleteAccount.endpoint),
    list: (request: AccountAPI.User.List.Req): ApiResponse<AccountAPI.User.List.Resp> =>
      this._(request, AccountAPI.User.List.endpoint),
  };

  openai = {
    complete: (
      request: AccountAPI.OpenAI.Complete.Req,
    ): ApiResponse<AccountAPI.OpenAI.Complete.Resp> =>
      this._(request, AccountAPI.OpenAI.Complete.endpoint),
  };

  paypal = {
    createSubscription: (
      request: AccountAPI.Paypal.CreateSubscription.Req,
    ): ApiResponse<AccountAPI.Paypal.CreateSubscription.Resp> =>
      this._(request, AccountAPI.Paypal.CreateSubscription.endpoint),
  };

  paddle = {
    createSubscription: (
      request: AccountAPI.Paddle.CreateSubscription.Req,
    ): ApiResponse<AccountAPI.Paddle.CreateSubscription.Resp> =>
      this._(request, AccountAPI.Paddle.CreateSubscription.endpoint),
  };
}
