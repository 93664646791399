import qs from 'qs';

export const getEmail = (search: string) => {
  const queryParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  if (queryParams && typeof queryParams.email === 'string') {
    return queryParams.email;
  }

  return '';
};

export const getParam = (param: string, search: string) => {
  const queryParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  if (queryParams && typeof queryParams[param] === 'string') {
    return queryParams[param];
  }

  return '';
};
