import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: `${location.pathname}${location.search}`,
      },
    });
  }, [location]);
};

export default usePageTracking;
