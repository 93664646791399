import { createContext } from 'react';
import { AuthContextType } from '../types';

const AuthContext = createContext<AuthContextType>({
  state: { token: '' },
  updateState: (val) => {
    console.log(val);
  },
});

export default AuthContext;
