import AuthContext from './AuthContext';
import React, { Component } from 'react';
import { IAuth, AuthContextType } from '../types';

class AuthProvider extends Component<React.FC> {
  initialState = {
    token: '',
  };
  state = { ...this.initialState };

  updateValue = (auth: IAuth): void => {
    this.setState((prevState) => {
      return { ...prevState, ...auth };
    });
  };

  render(): JSX.Element {
    return (
      <AuthContext.Provider
        value={{
          state: this.state,
          updateState: this.updateValue,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const useAuthContext = (): AuthContextType => React.useContext(AuthContext);

export { useAuthContext, AuthProvider };
