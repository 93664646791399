import { createContext } from 'react';
import { ProductContextType } from '../types';
import { findProductBySku } from '../helpers/products';
import { PaymentMethods } from '../const';

const ProductContext = createContext<ProductContextType>({
  state: {
    selectedProduct: findProductBySku(),
    isPurchased: false,
    paymentMethod: PaymentMethods.paypal,
  },
  updateState: (val) => {
    console.log(val);
  },
});

export default ProductContext;
